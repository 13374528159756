import { useMemo } from "react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { HeaderContentBookmark } from "scmp-app/components/header/header-content-bookmark";
import { HeaderContentCommentTrigger } from "scmp-app/components/header/header-content-comment-trigger";
import { HeaderContentShareWidgetDrawer } from "scmp-app/components/header/header-content-share-widget-drawer";
import type {
  Feature,
  HeaderConfiguration,
  ResponsiveLogoVariant,
} from "scmp-app/components/header/types";
import { DynamicHeaderAvatar } from "scmp-app/components/home/header/dynamics";
import { HeaderMyNews } from "scmp-app/components/home/header/header-my-news";
import {
  ActionButton,
  AvatarContainer,
  StyledIconSearch,
  StyleSearchLink,
} from "scmp-app/components/home/header/styles";
import type { ActionButtonProps, LogoProps } from "scmp-app/components/home/header/styles";
import type {
  FeatureConfiguration,
  HomeResponsiveFeature,
  PositionsConfiguration,
  PositionVariant,
} from "scmp-app/components/home/header/types";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { SearchEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { DynamicSubscriptionButton } from "scmp-app/lib/sdk/dynamics";
import type { OptionalBreakpointKey, RequiredBreakpointKey } from "scmp-app/lib/styles/responsive";
import { page as SearchPage } from "scmp-app/pages/search";

export const useHomeHeader = (shouldSwap: boolean, headerConfiguration?: HeaderConfiguration) => {
  const featureConfigurations = useMemo(() => {
    // If not provided, use default responsive features
    const homeResponsiveFeatures: HomeResponsiveFeature = {
      desktopUp: {
        default: { left: ["my-news"], right: ["subscription", "dashboard"] },
      },
      homeDesktopUp: {
        default: {
          left: ["search", "my-news"],
          right: ["subscription", "dashboard"],
        },
      },
      mobileUp: {
        default: { left: [], right: ["dashboard"] },
      },
      tabletUp: {
        default: { left: ["my-news"], right: ["subscription", "dashboard"] },
      },
      ...headerConfiguration?.homeResponsiveFeatures,
    };

    const computeFeatureConfiguration = (feature: Feature): PositionsConfiguration => {
      const defaultFeatureConfig: FeatureConfiguration = {
        isEnabled: false,
        responsiveVariants: {
          desktopUp: "hidden",
          homeDesktopUp: "hidden",
          mobileUp: "hidden",
          tabletUp: "hidden",
        },
      };

      const positions: PositionsConfiguration = {
        left: {
          ...defaultFeatureConfig,
        },
        right: {
          ...defaultFeatureConfig,
        },
      };

      Object.entries(positions).map(([position]) => {
        const computeFeatureListForBreakpoint = (
          breakpoint: RequiredBreakpointKey | OptionalBreakpointKey,
        ) => {
          const featureForBreakpoint = homeResponsiveFeatures[breakpoint];
          if (!featureForBreakpoint) return [];
          const features = shouldSwap
            ? featureForBreakpoint.swapped ?? featureForBreakpoint.default
            : featureForBreakpoint.default;
          return features[position as keyof PositionsConfiguration];
        };

        const responsiveVariants: ActionButtonProps["$responsiveVariants"] = {
          desktopUp: computeFeatureListForBreakpoint("desktopUp").includes(feature)
            ? "show"
            : "hidden",
          homeDesktopUp: computeFeatureListForBreakpoint("homeDesktopUp").includes(feature)
            ? "show"
            : "hidden",
          mobileUp: computeFeatureListForBreakpoint("mobileUp").includes(feature)
            ? "show"
            : "hidden",
          tabletUp: computeFeatureListForBreakpoint("tabletUp").includes(feature)
            ? "show"
            : "hidden",
        };

        const isEnabled = Object.values(responsiveVariants).includes("show");

        positions[position as keyof PositionsConfiguration] = {
          isEnabled,
          responsiveVariants,
        };
      });

      return positions;
    };

    const subscription = computeFeatureConfiguration("subscription");
    const dashboard = computeFeatureConfiguration("dashboard");
    const search = computeFeatureConfiguration("search");
    const myNews = computeFeatureConfiguration("my-news");
    const contentShare = computeFeatureConfiguration("content-share");
    const contentBookmark = computeFeatureConfiguration("content-bookmark");
    const contentComment = computeFeatureConfiguration("content-comment");

    return {
      contentBookmark,
      contentComment,
      contentShare,
      dashboard,
      myNews,
      search,
      subscription,
    };
  }, [headerConfiguration, shouldSwap]);

  const currentPageType = useCurrentPageType();
  const mastheadQueryParameter = {
    module: tracking.module.Masthead,
    pgtype: currentPageType,
  };

  const handleSearchClick = () => {
    // GA4 21.1.1
    sendGA4Tracking({
      action: "click",
      category: "search",
      subcategory: "icon",
    } as SearchEvent);
  };

  const handleMyNewsClick = () => {
    // GA4 10.1.1
    sendGA4Tracking({
      action: "click",
      category: "mynews",
      customized_parameters: {
        action_type: "redirect",
        trigger_point: "masthead",
      },
      subcategory: "mydaily",
    });
  };

  const renderFeatures = (
    position: PositionVariant,
    avatarId?: string,
  ): undefined | JSX.Element => {
    if (!featureConfigurations) return;
    const {
      contentBookmark,
      contentComment,
      contentShare,
      dashboard,
      myNews,
      search,
      subscription,
    } = featureConfigurations;

    const { pathname: searchPathname, query: searchQuery } = SearchPage.route({});

    return (
      <>
        <IdleQueue>
          {subscription[position].isEnabled && (
            <ActionButton $responsiveVariants={subscription[position].responsiveVariants}>
              <DynamicSubscriptionButton
                variant={
                  headerConfiguration?.appBarVariant === "scmp/magazines-style"
                    ? "dark"
                    : "light-blue"
                }
              />
            </ActionButton>
          )}
        </IdleQueue>

        {search[position].isEnabled && (
          <ActionButton $responsiveVariants={search[position].responsiveVariants}>
            <StyleSearchLink
              anchorProps={{ "aria-label": "search" }}
              as="/search"
              pathname={searchPathname}
              query={{ ...searchQuery, ...mastheadQueryParameter }}
              onClick={handleSearchClick}
            >
              <StyledIconSearch />
            </StyleSearchLink>
          </ActionButton>
        )}

        {myNews[position].isEnabled && (
          <ActionButton $responsiveVariants={myNews[position].responsiveVariants}>
            <BaseLink
              anchorProps={{ "aria-label": "my news" }}
              as="/mynews"
              pathname="mynews"
              query={mastheadQueryParameter}
              onClick={handleMyNewsClick}
            >
              <HeaderMyNews />
            </BaseLink>
          </ActionButton>
        )}

        {contentComment[position].isEnabled && (
          <ActionButton $responsiveVariants={contentComment[position].responsiveVariants}>
            <HeaderContentCommentTrigger />
          </ActionButton>
        )}
        {contentShare[position].isEnabled && (
          <ActionButton $responsiveVariants={contentShare[position].responsiveVariants}>
            <HeaderContentShareWidgetDrawer />
          </ActionButton>
        )}
        {contentBookmark[position].isEnabled && (
          <ActionButton $responsiveVariants={contentBookmark[position].responsiveVariants}>
            <HeaderContentBookmark />
          </ActionButton>
        )}
        {dashboard[position].isEnabled && (
          <ActionButton $responsiveVariants={dashboard[position].responsiveVariants}>
            <AvatarContainer>
              <IdleQueue>
                <DynamicHeaderAvatar avatarId={avatarId} />
              </IdleQueue>
            </AvatarContainer>
          </ActionButton>
        )}
      </>
    );
  };

  const responsiveLogoVariant = useMemo(() => {
    // If not provided, use default responsive logo variant
    const responsiveLogoVariant: ResponsiveLogoVariant = {
      desktopUp: {
        default: "logo",
      },
      homeDesktopUp: {
        default: "logo",
      },
      mobileUp: {
        default: "full",
      },
      tabletUp: {
        default: "full",
      },
      ...headerConfiguration?.responsiveLogoVariant,
    };

    const computeVariantForBreakpoint = (
      breakpoint: RequiredBreakpointKey | OptionalBreakpointKey,
    ) => {
      const variantForBreakpoint = responsiveLogoVariant[breakpoint];
      if (!variantForBreakpoint) return "full";
      const features = shouldSwap
        ? variantForBreakpoint.swapped ?? variantForBreakpoint.default
        : variantForBreakpoint.default;
      return features;
    };

    const responsiveVariants: LogoProps["$responsiveVariants"] = {
      desktopUp: computeVariantForBreakpoint("desktopUp"),
      homeDesktopUp: computeVariantForBreakpoint("homeDesktopUp"),
      mobileUp: computeVariantForBreakpoint("mobileUp"),
      tabletUp: computeVariantForBreakpoint("tabletUp"),
    };

    return responsiveVariants;
  }, [headerConfiguration, shouldSwap]);

  return {
    featureConfigurations,
    mastheadQueryParameter,
    renderFeatures,
    responsiveLogoVariant,
  };
};
